<template>
    <div
        class="qna-item"
        :class="{
            'has-reply': hasReply,
        }"
    >
        <div style="height: 28px"></div>
        <template v-if="!hasReply">
            <div>
                <div class="qna-item-tag">{{hasReply ? '답변완료' : '미답변'}}</div>
            </div>
            <div style="height: 14px"></div>
        </template>

        <template v-if="isArtist">
            <div class="qna-item-info">
                <!-- <p>{{$lib.t_genre(genre)}}<span style="margin: 0 8px">&VerticalSeparator;</span>{{eventType}}</p> -->
                <!-- <p>{{playDate}}<span style="margin: 0 12px" />{{address}}</p> -->
                <p>{{createdDate}}</p>
            </div>
            <div style="height: 10px"></div>
        </template>

        <template v-if="isClient">
            <div class="qna-item-info">
                <p>
                    <i class="profileimg" :style="{ backgroundImage: `url(${$lib.cdnUrl(teamImgUrl)})`}" />
                    <span class="name ui-ellipsis">{{teamName}}</span>
                    <span style="margin: 0 6px; font-weight:200">&VerticalSeparator;</span>
                    <span>{{$lib.t_genre(genre)}}</span>
                </p>
            </div>

            <div style="height: 10px"></div>
        </template>

        <div class="qna-item-question">{{detail}}</div>

        <template v-if="isArtist">
            <div style="height: 2.3rem"></div>
            <div>
                <button type="button" class="qna-item-btn" @click="() => goToDetail()">{{hasReply ? '답변보기' : '답변하기'}}</button>
            </div>
        </template>
        <template v-if="isClient && hasReply">
            <div style="height: 2rem"></div>
            <div>
                <button type="button" class="qna-item-btn" @click="() => goToDetail()">답변보기</button>
            </div>
        </template>

        <div style="height: 3rem"></div>
    </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
    name: 'QnaItem',
    components: {
        Icon,
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
    },
    computed: {
        userData() {
            return this.$store?.state?.user?.userData
        },
        isArtist() {
            return this.userData?.category === 'artist'
        },
        isClient() {
            return this.userData?.category === 'client'
        },
        /** 질문 */
        detail() {
            return this.row?.detail
        },
        /** 답변 */
        reply() {
            return this.row?.reply
        },
        /** 답변 등록일 */
        replyDate() {
            return this.row?.replyDate
        },
        /** 운영자전용 메모 */
        memo() {
            return this.row?.memo
        },
        /** 장르 */
        genre() {
            return (this.isClient ? this.row?.artistGenre : this.row?.genre) || ''
        },
        /** 이벤트 타입 */
        eventType() {
            return this.row?.eventType
        },
        /** 등록일 */
        createdDate() {
            return this.row?.createdDate > 0 && moment(this.row?.createdDate * 1000).format('YYYY.MM.DD HH:mm') || ''
        },
        /** 공연일시 */
        playDate() {
            return this.row?.playDate > 0 && moment(this.row?.playDate * 1000).format('YYYY.MM.DD') || ''
        },
        /** 주소 */
        address() {
            return this.row?.address
        },
        /** 전문가 닉네임 */
        teamName() {
            return this.row?.teamName || ''
        },
        /** 전문가 프로필 */
        teamImgUrl() {
            return this.row?.teamImgUrl || ''
        },
        artistidx() {
            return this.row?.artistidx
        },
        offerUid() {
            return this.row?.offerUid
        },
        hasReply() {
            return this.replyDate > 0
        },
        readonly() {
            return this.isClient || this.hasReply
        },
    },
    methods: {
        goToDetail() {
            if (this.$lib.execNgWordProcess(this.$store)) return

            this.$router.push({ name: 'QnaDetail' }, () => {
                this.$nextTick().then(() => {
                    this.$root.$emit('qna:update', {
                        uid: this?.row?.uid,
                        genre: this.genre,
                        eventType: this.eventType,
                        playDate: this.playDate,
                        detail: this.detail,
                        reply: this.reply,
                        replyDate: this.replyDate,
                        artistidx: this.artistidx,
                        offerUid: this.offerUid,
                    })
                })
            })
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.qna-item {
    .qna-item-tag {
        display: inline-flex;
        justify-content: center;
        width: auto;
        line-height: 2.3rem;
        height: 2.1rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
        background-color: #ff8f9f;
        text-align: center;
        border-radius: 3px;
        padding: 0 0.6rem;
    }
    .qna-item-info {
        p {
            display: flex;
            align-items: center;
            color: #909090;
            font-size: 18px;
            line-height: 1.5em;
            margin-bottom: 4px;

            span {
                &:not(.name) {
                    min-width: fit-content;
                }
            }

            &:last-child {
                margin-bottom: 0;
                &:first-child {
                    font-size: 1.7rem;
                }
            }
        }
        .profileimg {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
            display: inline-block;
            margin-right: 12px;
        }
    }
    .qna-item-question {
        color: #000;
        font-size: 1.9rem;
        max-height: 3.2em;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .qna-item-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border: 0px solid #b3b3b3;
        border-radius: 0.7rem;
        line-height: 6.2rem;
        height: 5.7rem;
        color: #555;
        cursor: pointer;
        font-weight: 500;
        font-size: 1.8rem;
        background-color: #f4f4f4
    }

    &.has-reply {
        .qna-item-tag {
            background-color: #979797;
        }
    }
}
</style>
