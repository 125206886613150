var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "qna-item",
      class: {
        "has-reply": _vm.hasReply
      }
    },
    [
      _c("div", { staticStyle: { height: "28px" } }),
      !_vm.hasReply
        ? [
            _c("div", [
              _c("div", { staticClass: "qna-item-tag" }, [
                _vm._v(_vm._s(_vm.hasReply ? "답변완료" : "미답변"))
              ])
            ]),
            _c("div", { staticStyle: { height: "14px" } })
          ]
        : _vm._e(),
      _vm.isArtist
        ? [
            _c("div", { staticClass: "qna-item-info" }, [
              _c("p", [_vm._v(_vm._s(_vm.createdDate))])
            ]),
            _c("div", { staticStyle: { height: "10px" } })
          ]
        : _vm._e(),
      _vm.isClient
        ? [
            _c("div", { staticClass: "qna-item-info" }, [
              _c("p", [
                _c("i", {
                  staticClass: "profileimg",
                  style: {
                    backgroundImage:
                      "url(" + _vm.$lib.cdnUrl(_vm.teamImgUrl) + ")"
                  }
                }),
                _c("span", { staticClass: "name ui-ellipsis" }, [
                  _vm._v(_vm._s(_vm.teamName))
                ]),
                _c(
                  "span",
                  { staticStyle: { margin: "0 6px", "font-weight": "200" } },
                  [_vm._v("❘")]
                ),
                _c("span", [_vm._v(_vm._s(_vm.$lib.t_genre(_vm.genre)))])
              ])
            ]),
            _c("div", { staticStyle: { height: "10px" } })
          ]
        : _vm._e(),
      _c("div", { staticClass: "qna-item-question" }, [
        _vm._v(_vm._s(_vm.detail))
      ]),
      _vm.isArtist
        ? [
            _c("div", { staticStyle: { height: "2.3rem" } }),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "qna-item-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.goToDetail()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.hasReply ? "답변보기" : "답변하기"))]
              )
            ])
          ]
        : _vm._e(),
      _vm.isClient && _vm.hasReply
        ? [
            _c("div", { staticStyle: { height: "2rem" } }),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "qna-item-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.goToDetail()
                    }
                  }
                },
                [_vm._v("답변보기")]
              )
            ])
          ]
        : _vm._e(),
      _c("div", { staticStyle: { height: "3rem" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }